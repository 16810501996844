<template>
  <div class="bet-amount-container">
    <div class="bet-amount__title">
      ¿Cuál es el monto que deseas apostar?
    </div>
    <div class="bet-amount__body">
      <div class="bet-amount__input">
        <div class="bet-amount__input__title">
          Monto total a apostar
        </div>
        <div class="bet-amount__input__box">
          <span>$ </span>
          <input
            ref="input"
            v-model="betAmount"
            type="text"
            @blur="validateAmount"
            @click="selectAllAmount"
            @change="onChangeAmount"
          />
          <span>mxn</span>
        </div>
      </div>
      <button class="bet-amount__button" @click="goNext">
        Siguiente
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import { clickBtnMontoSiguiente, clickInputTipsMontoTotalApostar } from '@/utils/analytics';

export default {
  name: 'SetBetAmount',
  data: () => ({}),
  mounted() {
    this.setBetAmount(100);
  },
  computed: {
    ...mapGetters('tips', ['getBetAmount']),
    ...mapGetters('loginGeneral', ['isLoggedIn', 'getPremiumAccount', 'getIsGolstats']),
    betAmount: {
      get: function() {
        return this.getBetAmount;
      },
      set: function(newVal) {
        const positiveRegex = new RegExp(/^[1-9]\d*$/);
        if (positiveRegex.test(newVal)) {
          this.setBetAmount(newVal);
        } else {
          this.$refs['input'].value = this.betAmount;
        }
      },
    },
  },
  methods: {
    ...mapMutations('tips', ['setBetAmount', 'setUserType']),
    ...mapActions('alertGeneral', ['setAlert']),
    goNext() {
      clickBtnMontoSiguiente(this.betAmount, this.getPremiumAccount, this.getIsGolstats);

      if (Number(this.betAmount) >= 50) {
        if (this.isLoggedIn) {
          this.setUserType(2);
          this.$router.push('/choose-the-risk');
        } else {
          this.$router.push('/set-bet-user');
        }
      } else {
        this.setAlert({
          type: 'error',
          message: 'El monto mínimo de apuesta es $50.',
          duration: 4000,
          passwordInvalid: true,
        });
      }
    },
    validateAmount() {
      if (Number(this.betAmount) < 50) {
        this.setBetAmount(100);
        this.$refs['input'].value = 100;
      }
    },
    onChangeAmount(event) {
      clickInputTipsMontoTotalApostar(this.getPremiumAccount, Number(event.target.value), this.getIsGolstats);
    },
    selectAllAmount() {
      this.$refs.input.select();
    },
  },
};
</script>

<style lang="scss" scoped>
.bet-amount {
  &-container {
    padding: 75px 0px 1rem 0px;
    display: flex;
    flex-flow: column;
    justify-content: start;
    align-items: center;
    background: url('https://az755631.vo.msecnd.net/golstats-bets/set-bet-page.jpg') center/cover no-repeat;
    min-height: 100%;
    height: max-content;
  }
  &__title {
    font-family: Circular-Std-Bold;
    font-size: 25px;
    letter-spacing: -1.36px;
    margin: 20px;
    color: #fff;
    padding: 5px 40px;
    border-radius: 13px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(225, 225, 225, 0.14);
  }
  &__body {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  &__input {
    align-items: flex-start;
    display: flex;
    padding: 0px 30px 2px 0px;
    flex-flow: column;
    &__title {
      font-family: Circular-Std-Bold;
      font-size: 10px;
      letter-spacing: 0.17px;
      text-transform: uppercase;
      color: #fff;
    }
    &__box {
      display: flex;
      font-family: Avenir-Pro-Bold;
      font-size: 14px;
      letter-spacing: -0.11px;
      color: #fff;
      border: solid 1px #b0b0b0;
      background-color: rgba(255, 255, 255, 0.1);
      width: 180px;
      height: 30px;
      margin: 5px 0 0;
      padding: 3px 52px 0 20px;
      border-radius: 3.5px;
      input {
        width: 70px;
        background-color: transparent;
        font-family: Avenir-Pro-Bold;
        outline: none;
        border: none;
        color: #fff;
        &:focus {
          border: none;
          outline: none;
        }
      }
      span:first-child {
        padding-top: 3px;
      }
      span:last-child {
        font-family: Avenir-Regular;
        font-size: 13px;
        line-height: 2.1;
        letter-spacing: -0.09px;
        color: #fff;
      }
    }
  }
  &__button {
    padding: 0px 25px;
    border-radius: 21px;
    border: solid 2px #c9e8af;
    background-color: transparent;
    font-family: Circular-Std;
    font-size: 15px;
    height: 32px;
    letter-spacing: -0.12px;
    color: #ffffff;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: #b9e097;
    }
  }
}
@media only screen and (max-width: 768px) {
  .bet-amount {
    &-container {
      padding: 25px 0px 1rem 0px;
      background: url('https://az755631.vo.msecnd.net/golstats-bets/set-bet-mobile.jpg') center/cover no-repeat;
    }
    &__title {
      margin: 20px 10%;
    }
    &__body {
      flex-flow: column;
      align-items: center;
    }
    &__input {
      padding: 40px 0px 20px 0px;
      align-items: center;
    }
  }
}
@media only screen and (max-width: 374px) {
  .bet-amount {
    &__title {
      font-size: 20px;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .bet-amount-container {
    zoom: 1.2;
  }
}
</style>
